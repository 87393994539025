<template>
    <q-layout>
        <!-- TOP / BRAND -->
        <!-- navbar -->
        <navbar></navbar>

        <!-- SLIDER -->
        <q-carousel
            arrows
            animated
            infinite
            :autoplay="5000"
            :swipeable="true"
            width="100%"
            height="600px" v-model="slide">
            <q-carousel-slide name="1" class="flex justify-center" img-src="@/assets/media/slides/ibc-international-bio-cosmetics-social-media-slide-2.jpg">
                <div class="my-auto mx-auto  w-1/2">
                    <h1 class="text-center text-white text-7xl font-black font-epigrafica uppercase">Motivé par la Beauté</h1>
                    <h1 class="text-white text-center text-2xl mt-2">Nous révélons votre beauté aux yeux du monde</h1>
                </div>
            </q-carousel-slide>
            <q-carousel-slide name="2" class="flex justify-center" img-src="@/assets/media/slides/ibc-international-bio-cosmetics-social-media-slide-1.jpg">
                <div class="my-auto mx-auto w-1/2">
                    <h1 class="text-center text-white text-7xl font-black font-epigrafica uppercase">Inspiré par la nature</h1>
                    <h1 class="text-white text-center text-2xl mt-2">Grâce à des ingrédients naturels</h1>
                </div>
            </q-carousel-slide>
            <q-carousel-slide name="3" class="flex justify-center" img-src="@/assets/media/slides/ibc-international-bio-cosmetics-social-media-slide-3.jpg">
                <div class="my-auto mx-auto  w-1/2">
                    <h1 class="text-center text-white text-7xl font-black font-epigrafica uppercase">Guidé par la qualité</h1>
                    <h1 class="text-white text-center text-2xl mt-2">Afin de produire le meilleur que vous méritez</h1>
                </div>
            </q-carousel-slide>
        </q-carousel>   

        <!-- ENGAGEMENTS -->
        <div class="container mx-auto py-20">
            <div class="row justify-around">
                <div class="col-12 col-sm-6 col-md-3 flex flex-col justify-center items-center">
                    <q-img fit="cover" width="100px" src="@/assets/media/engagements/ibc-international-bio-cosmetics-engagements-icon-save-earth.svg" no-spinner no-transition/>
                    <p class="text-mineralgreen text-center text-base mt-2 px-20">Respectueuse de la nature</p>
                </div>
                <div class="col-12 col-sm-6 col-md-3 flex flex-col justify-center items-center">
                    <q-img fit="cover" width="100px" src="@/assets/media/engagements/ibc-international-bio-cosmetics-engagements-icon-100-natural-ingredients.svg" no-spinner no-transition/>
                    <p class="text-mineralgreen text-center text-base mt-2 px-20">Des ingrédients naturels</p>
                </div>
                <div class="col-12 col-sm-6 col-md-3 flex flex-col justify-center items-center">
                    <q-img fit="cover" width="100px" src="@/assets/media/engagements/ibc-international-bio-cosmetics-engagements-icon-tested-derma.svg" no-spinner no-transition/>
                    <p class="text-mineralgreen text-center text-base mt-2 px-20">Testés et approuvés par des dermatologues</p>
                </div>
                <div class="col-12 col-sm-6 col-md-3 flex flex-col justify-center items-center">
                    <q-img fit="cover" width="100px" src="@/assets/media/engagements/ibc-international-bio-cosmetics-engagements-icon-packs-bio.svg" no-spinner no-transition/>
                    <p class="text-mineralgreen text-center text-base mt-2 px-20">Un design qui vous correspond</p>
                </div>
            </div>
        </div>

        <!-- SUMMARY PRESENTATION -->
        <div class="container mx-auto py-10">
            <div class="row">
                <div class="col-12 flex flex-col items-center">
                    <h1 class="text-mineralgreen text-center text-4xl font-black font-epigrafica mt-2 uppercase">Votre créateur de cosmétiques naturelles</h1>
                    <div class="bg-skincolor p-1 mt-3 w-72"></div>
                </div>
                <div class="row q-pa-sm items-center justify-center py-20">
                    <div class="col-12 col-sm-12 col-md-6 col-lg-4 px-12">
                        <q-img width="100%" src="@/assets/media/brands/ibc-international-bio-cosmetics-default-vertical-logotype.svg" no-spinner />
                    </div>
                    <div class="col-12 col-sm-12 col-md-6 col-lg-4 p-5">
                        <p class="font-epigrafica text-mineralgreen text-xl text-justify font-medium mb-5">
                            International Bio Cosmetics (IBC) est une entreprise spécialisée dans la fabrication, la production et la commercialisation 
                            de produits cosmétiques essentiellement à base d’ingrédients et de plantes naturelles. <br><br>
                            
                            Créée et implantée en Côte d’ivoire depuis 2019, IBC propose par l’expertise de son laboratoire, des produits de beauté 
                            efficaces et innovants inspirés de nos recettes de grand-mère.
                        </p>
                        <button class="bg-skincolor hover:bg-mineralgreen text-white font-bold py-2 px-4 rounded-full">
                            En savoir plus
                        </button>
                    </div>
                </div>
            </div>
        </div>

        <div class="bg-skincolor p-1"></div>

        <!-- SECTION PREVIEWER -->
        <!----------------------->
        <!-- SPEAK ABOUT BIO -->
        <div class="py-14 bg-cover bg-nature-drop-water row">
            <div class="col-12 flex flex-col items-center">
                <h1 class="text-white text-center text-4xl font-black font-epigrafica mt-2 uppercase">Parlons Cosmétique Naturelle !</h1>
                <div class="bg-skincolor p-1 mt-3 w-72"></div>
            </div>
            <div class="container mx-auto py-16">
                <div class="row">
                    <div class="col-12 col-sm-12 col-md-6 p-5">
                        <p class="text-skincolor text-3xl font-black font-epigrafica my-4 uppercase">La Nature est notre force !</p>
                        <p class="font-epigrafica text-white text-xl font-medium">
                            La majeure partie des ingrédients présents dans nos formules sont
                            d’origine naturelle. Nous avons trouvé nos trésors 
                            de beauté dans le terroir local. Ainsi nous nous engageons à prendre soin de votre peau
                            mais aussi de la nature.<br><br>
                            Si nos formules sont respectueuses de la santé de votre peau, nous avons aussi porté une
                            attention toute particulière à la création de nos packagings et emballages.
                        </p>
                    </div>
                </div>
            </div>
        </div>

        <div class="bg-skincolor p-1"></div>

        <!-- FEATURED CONTENT -->
        <div class="container mx-auto py-36">
            <div class="row justify-around">
                <div class="col-12 col-sm-12 col-md-4 p-5 img-smooth-zoom">
                    <div class="col-12 flex flex-col items-center mb-14">
                        <h1 class="text-mineralgreen text-center text-2xl font-black font-epigrafica mt-2 uppercase">L'Ingrédient du mois</h1>
                        <div class="bg-skincolor p-1 mt-3 w-20"></div>
                    </div>
                    <div class="static row justify-around">
                        <q-img class="drop-shadow-xl" width="100%" src="@/assets/media/posts/ibc-international-bio-cosmetics-ingredient-du-mois-avocat.jpg" no-spinner />
                        <div class="bg- h-4 w-11/12"></div>
                    </div>
                    <div>
                        <p class="text-skincolor text-xl font-semibold mt-5">Le pouvoir régénérateur de l’avocat</p>
                        <p class="text-mineralgreen text-lg text-justify break-words my-3">
                            Délicieux, l’avocat est un fruit bon pour l’organisme,
                            mais celui-ci est aussi excellent pour l’épiderme et la
                            fibre capillaire. Composé de vitamines A, C et E,
                            l’avocat est également riche en nutriments...
                        </p>
                        <q-btn to="/page/ingredient/3/avocat" unelevated label="En savoir plus" class="bg-skincolor hover:bg-mineralgreen text-white font-bold py-2 px-4 rounded-full capitalize" />
                    </div>
                </div>
                <div class="col-12 col-sm-12 col-md-4 p-5 img-smooth-zoom">
                    <div class="col-12 flex flex-col items-center mb-14">
                        <h1 class="text-mineralgreen text-center text-2xl font-black font-epigrafica mt-2 uppercase">Le Conseil d'Expert du jour</h1>
                        <div class="bg-skincolor p-1 mt-3 w-20"></div>
                    </div>
                    <div class="static row justify-around">
                        <q-img class="drop-shadow-xl" width="100%" src="@/assets/media/posts/ibc-international-bio-cosmetics-conseil-expert-du-jour-masque-carotte.jpg" no-spinner />
                        <div class="bg- h-4 w-11/12"></div>
                    </div>
                    <div>
                        <p class="text-skincolor text-xl font-semibold mt-5">Masque antirides fait maison à la Carotte</p>
                        <p class="text-mineralgreen text-lg text-justify break-words my-3">
                            Ce masque antirides maison à la carotte va illuminer
                            votre teint ! La béta-carotène (qui lui donne sa couleur
                            orange) et la vitamine C revitalise la peau... et lui donne
                            bonne mine instantanément !...
                        </p>
                        <q-btn to="/page/expert-counseling/1/masque-antirides-a-la-carotte-fait-maison" unelevated label="En savoir plus" class="bg-skincolor hover:bg-mineralgreen text-white font-bold py-2 px-4 rounded-full capitalize" />
                    </div>
                </div>
            </div>
        </div>

        <div class="bg-skincolor p-1"></div>

        <!-- INSTAGRAM CONTENT -->
        <div class="row bg-lighty pt-9 pb-24">
            <div class="col-12 flex flex-col items-center mb-5">
                <p class="text-mineralgreen text-center text-2xl font-black font-epigrafica mt-2 uppercase">International Bio Cosmetics sur Instagram</p>
                <p class="text-mineralgreen-dark text-lg text-center break-words">@international_bio_cosmetics</p>
            </div>
            <div class="col-12">
                <q-carousel
                    v-model="slide2"
                    transition-prev="slide-right"
                    transition-next="slide-left"
                    animated
                    infinite
                    height="400px"
                    class="bg-transparent">
                        <q-carousel-slide name="1" class="colrowumn p-0 no-wrap">
                            <div class="row fit justify-start items-center no-wrap bg-transparent">
                                <q-img class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 full-height" src="@/assets/media/instagram/ibc-international-bio-cosmetics-instagram-1.jpg" />
                                <q-img class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 full-height" src="@/assets/media/instagram/ibc-international-bio-cosmetics-instagram-2.jpg" />
                                <q-img class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 full-height" src="@/assets/media/instagram/ibc-international-bio-cosmetics-instagram-3.jpg" />
                                <q-img class="col-12 col-sm-6 col-md-6 col-lg-3 col-xl-3 full-height" src="@/assets/media/instagram/ibc-international-bio-cosmetics-instagram-4.jpg" />
                            </div>
                        </q-carousel-slide>
                </q-carousel>
            </div>
        </div>

        <!-- FOOTER CONTENT -->
        <!-- footer -->
        <footerbar></footerbar>
        
    </q-layout>
</template>

<script>
import navbar from "@/views/shared/Navbar"
import footerbar from "@/views/shared/Footer"
import { useMeta } from "quasar"

export default {
    name: "Index",
    components: { navbar, footerbar },
    data() {
        return {
            slide: '1',
            slide2: '1',
        }
    },
    created () {
        useMeta({
            title: 'International Bio Cosmetics | Accueil', // sets document title
        })
    }
};
</script>